<template>
  <basic-layout>
    <div class="sp-wizzard">
      <div class="header">
        <router-link v-if="noEdit" to="/stockPickingLab">
          <back-icon />
          <h2>{{ $t("stockPickingLab.wizzard.moduleTitle") }}</h2>
        </router-link>
        <h2 v-else>{{ $t("stockPickingLab.wizzard.moduleTitle") }}</h2>
        <div class="module-desc">
          <h3>{{ $t("stockPickingLab.wizzard.moduleSubtitle") }}</h3>
          <p>
            {{ $t("stockPickingLab.wizzard.moduleDesc") }}
          </p>
        </div>
      </div>
      <b-container class="wz-content">
        <router-link
          class="prev-icon"
          :to="prevURL"
          v-if="$route.path !== '/wizzard' && !noEdit"
        >
          <arrow-left-icon :size="64" />
        </router-link>
        <router-view />
        <router-link
          class="next-icon"
          to="wizzard/step-two"
          v-if="$route.path === '/wizzard' && !noEdit"
        >
          <arrow-right-icon :size="64" />
        </router-link>
      </b-container>
    </div>
  </basic-layout>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ArrowLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ArrowRightIcon from "vue-material-design-icons/ChevronRight.vue";
import BackIcon from "vue-material-design-icons/ArrowLeft.vue";
import BasicLayout from "../layouts/BasicLayout.vue";
import { WIZZARD_STEPS } from "../utils/constants";
export default {
  components: { ArrowRightIcon, ArrowLeftIcon, BasicLayout, BackIcon },
  name: "wizzard",
  data() {
    return {
      steps: WIZZARD_STEPS,
    };
  },
  computed: {
    ...mapGetters("wizzardStore", ["noEdit"]),
    prevURL() {
      if (this.$route.query.noEdit) {
        return null;
      } else {
        return this.steps[this.steps.indexOf(this.$route.path) - 1];
      }
    },
  },
  methods: {
    ...mapMutations("wizzardStore", ["setNoEdit"]),
  },
  mounted() {
    if (this.$route.query.noEdit) {
      this.setNoEdit(true);
    }
  },
};
</script>

<style lang="scss" scoped>
.sp-wizzard {
  .header {
    margin: 0 -25px;
    text-align: center;
    margin-bottom: toRem(55);
    a {
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: toRem(18);
      margin-left: toRem(20);
      h2 {
        margin-bottom: 0;
        margin-left: toRem(10);
      }
    }
    h2 {
      margin-bottom: toRem(18);
      font-weight: 700;
      font-size: toRem(24);
      font-family: Roboto;
    }
    h3 {
      font-family: Roboto;
      font-size: toRem(16);
      font-weight: 700;
    }
    .module-desc {
      padding: toRem(16) 0;
      background: $blueBackground;
      p {
        max-width: toRem(720);
        margin: 0 auto;
      }
    }
  }
  .wz-content {
    position: relative;
    .next-icon {
      position: absolute;
      top: 50%;
      right: -75px;
    }
    .prev-icon {
      position: absolute;
      top: 50%;
      left: -75px;
    }
  }
  padding-bottom: toRem(50);
  @media screen and (max-width: $lg) {
    .wz-content {
      .next-icon {
        right: 5px;
      }
      .prev-icon {
        left: 5px;
      }
      .next-icon,
      .prev-icon {
        position: fixed;
      }
    }
  }
}
</style>