import { render, staticRenderFns } from "./Wizzard.vue?vue&type=template&id=3c913ecd&scoped=true&"
import script from "./Wizzard.vue?vue&type=script&lang=js&"
export * from "./Wizzard.vue?vue&type=script&lang=js&"
import style0 from "./Wizzard.vue?vue&type=style&index=0&id=3c913ecd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c913ecd",
  null
  
)

export default component.exports