<template>
  <div
    :class="[{ expanded: showMore }, 'wizzard-card']"
    :style="`background-color:${bg}`"
  >
    <span class="title">{{ title }}</span>
    <div class="text">
      <p>
        {{ computedText }}
        <button class="btn-link" @click="showMore = !showMore" v-if="longText">
          <arrow-icon-down v-if="!showMore" :size="16" />
          <arrow-icon-up v-else :size="16" />
          {{ !showMore ? $t("general.expand") : $t("general.shorten") }}
        </button>
      </p>
    </div>
    <img :src="imgUrl" />
  </div>
</template>

<script>
import ArrowIconDown from "vue-material-design-icons/ChevronDoubleDown.vue";
import ArrowIconUp from "vue-material-design-icons/ChevronDoubleUp.vue";
export default {
  name: "wizzard-card",
  props: {
    title: String,
    text: String,
    imgUrl: String,
    bg: String,
    length: Number,
  },
  components: {
    ArrowIconDown,
    ArrowIconUp,
  },
  data() {
    return {
      showMore: false,
      longText: this.text.length > this.length,
    };
  },
  computed: {
    computedText() {
      if (!this.showMore && this.longText) {
        return this.text.substring(0, this.length) + "...";
      }
      return this.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.wizzard-card {
  border-radius: 15px;
  width: 225px;
  padding: toRem(16);
  display: flex;
  flex-flow: column;
  height: 330px;
  min-height: 330px;
  &.expanded {
    height: fit-content;
    img {
      display: none;
    }
  }
  .title {
    font-size: toRem(16);
    font-weight: 600;
    display: block;
    text-align: center;
  }
  .text {
    padding: toRem(12) 0;
    p {
      font-size: toRem(14);
    }
  }
  img {
    margin: toRem(16) auto;
    margin-top: auto;
    display: block;
    height: 30%;
  }
  .btn-link {
    color: #4f4f4f;
    font-weight: 600;
    padding-left: 0;
    &:hover,
    &:focus {
      text-decoration: none;
      font-weight: bolder;
      color: #000;
    }
  }
}
</style>