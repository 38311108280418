<template>
  <div class="step-heading">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "step-heading",
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.step-heading {
  p {
    text-align: center;
    margin-bottom: 32;
    min-height: 18px;
  }

  h4 {
    text-align: center;
    margin-bottom: toRem(23);
  }
}
</style>