<template>
  <div class="large-card-outter">
    <div
      class="large-card-inner"
      :style="`background-color:${bg}`"
      @click="handleCardClick"
    >
      <span class="card-title">{{ title }} </span>
      <p class="card-attr body-bold" v-if="attribute">
        {{ attribute }}
      </p>
      <p class="card-desc" v-html="description" />
      <img :src="imgUrl" />
    </div>
    <b-button
      v-if="!noEdit"
      variant="primary"
      :disabled="btnDisabled"
      @click="$emit('click', id)"
      >{{
        btnDisabled ? $t("stockPickingLab.wizzard.unavailableService") : btnText
      }}</b-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "wizzardLargeCard",
  props: {
    title: String,
    attribute: String,
    description: String,
    imgUrl: String,
    btnText: String,
    btnDisabled: Boolean,
    bg: String,
    id: [String, Number],
  },
  methods: {
    handleCardClick() {
      if (!this.btnDisabled && !this.noEdit) {
        this.$emit("click", this.id);
      }
    },
  },
  computed: {
    ...mapGetters("wizzardStore", ["noEdit"]),
  },
};
</script>

<style lang="scss" scoped>
.large-card-outter {
  margin-bottom: toRem(30);
  .large-card-inner {
    min-height: 400px;
    border-radius: toRem(16);
    padding: toRem(18);
    max-width: toRem(300);
    height: calc(100% - 32px);
    display: flex;
    flex-flow: column;
    cursor: pointer;
    img {
      margin: auto;
      margin-bottom: toRem(18);
    }
    .card-title {
      font-family: Work Sans;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      display: block;
      margin-bottom: toRem(22);
    }
  }
  .btn {
    margin: 16px auto 0 auto;
  }
}
</style>