<template>
  <div>
    <step-heading
      :title="$t('stockPickingLab.wizzard.step1.title')"
      :description="$t('stockPickingLab.wizzard.step1.description')"
    />
    <div class="cards-container">
      <wizzard-card v-for="(card, i) in cards" v-bind="card" :key="i" />
    </div>
  </div>
</template>

<script>
import StepHeading from "./StepHeading.vue";
import WizzardCard from "./WizzardCard.vue";
import card1 from "../../assets/img/wizzardCards/card1.png";
import card2 from "../../assets/img/wizzardCards/card2.png";
import card3 from "../../assets/img/wizzardCards/card3.png";
import card4 from "../../assets/img/wizzardCards/card4.png";
import card5 from "../../assets/img/wizzardCards/card5.png";
import card6 from "../../assets/img/wizzardCards/card6.png";
import card7 from "../../assets/img/wizzardCards/card7.png";
import card8 from "../../assets/img/wizzardCards/card8.png";
import card9 from "../../assets/img/wizzardCards/card9.png";
import card10 from "../../assets/img/wizzardCards/card10.png";
import card11 from "../../assets/img/wizzardCards/card11.png";
import card12 from "../../assets/img/wizzardCards/card12.png";

export default {
  components: { WizzardCard, StepHeading },
  name: "first-step-wizzard",
  data() {
    return {
      cards: [
        {
          title: this.$t("stockPickingLab.wizzard.step1.card1.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card1.text"),
          bg: "#C4E9E7",
          imgUrl: card1,
          length: 230
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card2.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card2.text"),
          bg: "#F7C4A5",
          imgUrl: card2,
          length: 230
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card3.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card3.text"),
          bg: "#E3DAFB",
          imgUrl: card3,
          length: 172
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card4.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card4.text"),
          bg: "#B1E0E7",
          imgUrl: card4,
          length: 187
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card5.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card5.text"),
          bg: "#E8E2E3",
          imgUrl: card5,
          length: 180
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card6.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card6.text"),
          bg: "#FBDADA",
          imgUrl: card6,
          length: 213
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card7.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card7.text"),
          bg: "#DAF5F9",
          imgUrl: card7,
          length: 180
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card8.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card8.text"),
          bg: "#CFCEE9",
          imgUrl: card8,
          length: 230
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card9.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card9.text"),
          bg: "#C4E9E7",
          imgUrl: card9,
          length: 200
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card10.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card10.text"),
          bg: "#ece5ff",
          imgUrl: card10,
          length: 160
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card11.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card11.text"),
          bg: "#FCDBC7",
          imgUrl: card11,
          length: 150
        },
        {
          title: this.$t("stockPickingLab.wizzard.step1.card12.title"),
          text: this.$t("stockPickingLab.wizzard.step1.card12.text"),
          bg: "#ffe9ed",
          imgUrl: card12,
          length: 230
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.cards-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: calc((100% - 900px) / 3);
  row-gap: toRem(32);
  @media screen and (max-width: $lg) {
    column-gap: calc((100% - 675px) / 2);
  }
  @media screen and (max-width: $md) {
    column-gap: calc(100% - 450px);
  }
  @media screen and (max-width: $sm) {
    justify-content: center;
  }
}
</style>