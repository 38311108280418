<template>
  <div class="fourth-step-wizzard">
    <step-heading
      :title="$t('stockPickingLab.wizzard.step4.title')"
      :description="$t('stockPickingLab.wizzard.step4.description')"
    />
    <div class="selected-options">
      <div class="selected-strategy">
        {{ $t("stockPickingLab.wizzard.investmentStrategy") }}:
        <span class="body-bold">
          {{ $t(`preferences.strategies.${selectedStrategy}.name`) }}
        </span>
      </div>
      <div class="selected-timeframe">
        <span> {{ $t("general.rebalancingFrequency") }}: </span>
        <report-type :type="selectedTimeframe" />
        <span class="body-bold">
          {{ getTypeTranslation(selectedTimeframe) }}
        </span>
      </div>
    </div>
    <div class="strategy-detail">
      <div class="strategy-text">
        <div class="strategy-indicators">
          <highlighted-box
            :value="activeIndicator.performance"
            :description="
              $t('preferences.strategies.comparisonTable.cagrAlternative')
            "
          />
          <highlighted-box
            :value="activeIndicator.dd"
            :description="$t('preferences.strategies.comparisonTable.maxDd')"
          />
          <highlighted-box
            :value="activeIndicator.custom"
            :description="activeIndicator.customTitle"
          />
        </div>
        <p
          v-html="$t(`preferences.strategies.${selectedStrategy}.description`)"
        />
      </div>
      <div class="strategy-chart">
        <performance-chart
          :strategy="selectedStrategy"
          :timeframe="selectedTimeframe"
          :routing="false"
        />
      </div>
    </div>
    <b-button
      variant="secondary"
      class="finish-btn"
      @click="handleSetPreferences"
      :disabled="isChangingPreferences"
    >
      <check-icon :size="20" v-if="!isChangingPreferences" />
      <b-spinner small v-else />
      {{ $t("stockPickingLab.wizzard.getStarted") }}
    </b-button>
  </div>
</template>

<script>
import { BALANCED_STRATEGY, LONG_ONLY } from "../../utils/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CheckIcon from "vue-material-design-icons/Check.vue";
import HighlightedBox from "./HighlightedBox.vue";
import MainAlert from "../alerts/MainAlert.vue";
import PerformanceChart from "../charts/PerformanceChart.vue";
import ReportType from "../tables/tableComponents/reportComponents/ReportType.vue";
import StepHeading from "./StepHeading.vue";
import { getTypeTranslation } from "../../utils/translationHelper";
import { strategyApiTranslator } from "../../utils/helper";
export default {
  name: "fourth-step-wizzard",
  components: {
    CheckIcon,
    StepHeading,
    HighlightedBox,
    PerformanceChart,
    ReportType,
  },
  data() {
    return {
      indicators: [
        {
          strategy: LONG_ONLY,
          performance: "17,25 ",
          dd: "-45,54 ",
          custom: "8,21 ",
          customTitle: this.$i18n.t(
            "stockPickingLab.wizzard.step4.longOnlyCustomTitle"
          ),
        },
        {
          strategy: BALANCED_STRATEGY,
          performance: "13,75 ",
          dd: "-17,10 ",
          custom: "30,41 ",
          customTitle: this.$i18n.t(
            "stockPickingLab.wizzard.step4.balancedCustomTitle"
          ),
        },
      ],
      isChangingPreferences: false,
    };
  },
  methods: {
    ...mapActions("wizzardStore", ["updatePreferences"]),
    ...mapMutations(["updateUser"]),
    getTypeTranslation,
    handleSetPreferences() {
      const ApiStrategy = strategyApiTranslator(this.selectedStrategy, "api");
      this.isChangingPreferences = true;
      this.updatePreferences(ApiStrategy)
        .then(() => {
          this.updateUser({
            preferred_strategy: this.selectedStrategy,
          });
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "stockPickingLab.wizzard.preferencesUpdated"
              ),
            },
          });
          this.$router.push("/stockPickingLab");
          this.$cookies.set("wizzardFinished", true);
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("errors.actionFailed"),
            },
          });
        })
        .finally(() => (this.isChangingPreferences = false));
    },
  },
  computed: {
    ...mapGetters("wizzardStore", ["selectedTimeframe", "selectedStrategy"]),
    activeIndicator() {
      return this.indicators.find((i) => i.strategy === this.selectedStrategy);
    },
  },
};
</script>

<style lang="scss" scoped>
.fourth-step-wizzard {
  .selected-options {
    display: flex;
    column-gap: toRem(32);
    justify-content: center;
    margin-bottom: toRem(39);
    .selected-timeframe {
      display: flex;
      align-items: center;
      .report-type {
        margin: 0 5px;
      }
      span {
        white-space: nowrap;
      }
    }
  }
  .strategy-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .strategy-chart,
    .strategy-text {
      width: calc(50% - 15px);
      @media screen and (max-width: $lg) {
        width: 100%;
      }
      .strategy-indicators {
        display: flex;
        column-gap: 15px;
        justify-content: center;
        margin-bottom: toRem(33);
      }
    }
  }
  .finish-btn {
    display: block;
    margin: toRem(16) auto;
  }
}
</style>