<template>
  <div class="highlighted-box">
    <div class="box-content">
      <span class="value">{{ value }}%</span>
      <span class="description">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "highlighted-box",
  props: {
    value: [Number, String],
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.highlighted-box {
  box-shadow: 0px 0px 15px #b1d0ff;
  border-radius: 15px;
  padding: 4px;
  width: 135px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .value {
    color: black;
    font-size: toRem(24);
  }
  .description {
    color: $inactive;
    font-style: italic;
    min-height: 36px;
  }
  .value,
  .description {
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>
