<template>
  <div class="third-step">
    <step-heading
      :title="$t('stockPickingLab.wizzard.step3.title')"
      :description="$t('stockPickingLab.wizzard.step3.description')"
    />
    <div class="options">
      <wizzard-large-card
        v-for="(card, i) in cards"
        :key="i"
        v-bind="card"
        @click="handleSetSelectedTimeframe"
      />
    </div>
  </div>
</template>

<script>
import { SP_TIMEFRAMES_OPTIONS, WIZZARD_STEPS } from "../../utils/constants";
import StepHeading from "./StepHeading.vue";
import WizzardLargeCard from "./WizzardLargeCard.vue";
import dailyImg from "../../assets/img/wizzardCards/daily_graphics.png";
import { mapMutations } from "vuex";
import monthlyImg from "../../assets/img/wizzardCards/monthly_graphics.png";
import yearlyImg from "../../assets/img/wizzardCards/annual_graphics.png";
export default {
  name: "third-step-wizzard",
  components: { WizzardLargeCard, StepHeading },
  data() {
    return {
      cards: [
        {
          title: this.$t("stockPickingLab.wizzard.step3.card1.title"),
          description: this.$t(
            "stockPickingLab.wizzard.step3.card1.description"
          ),
          imgUrl: dailyImg,
          btnText: this.$t("stockPickingLab.wizzard.step3.card1.title"),
          btnDisabled: true,
          bg: "#99F2DC",
          id: SP_TIMEFRAMES_OPTIONS()[0].value,
        },
        {
          title: this.$t("stockPickingLab.wizzard.step3.card2.title"),
          description: this.$t(
            "stockPickingLab.wizzard.step3.card2.description"
          ),
          imgUrl: monthlyImg,
          btnText: this.$t("stockPickingLab.wizzard.step3.card2.title"),
          btnDisabled: false,
          bg: "#E2A1F3",
          id: SP_TIMEFRAMES_OPTIONS()[1].value,
        },
        {
          title: this.$t("stockPickingLab.wizzard.step3.card3.title"),
          description: this.$t(
            "stockPickingLab.wizzard.step3.card3.description"
          ),
          imgUrl: yearlyImg,
          btnText: this.$t("stockPickingLab.wizzard.step3.card3.title"),
          btnDisabled: true,
          bg: "#F3AD85",
          id: SP_TIMEFRAMES_OPTIONS()[2].value,
        },
      ],
    };
  },
  methods: {
    ...mapMutations("wizzardStore", ["setSelectedTimeframe"]),
    handleSetSelectedTimeframe(id) {
      this.setSelectedTimeframe(id);
      this.$router.push(WIZZARD_STEPS[3]);
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  display: flex;
  column-gap: toRem(42);
  justify-content: center;
  flex-wrap: wrap;
}
</style>