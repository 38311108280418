<template>
  <div class="second-step">
    <step-heading
      :title="$t('stockPickingLab.wizzard.step2.title')"
      :description="$t('stockPickingLab.wizzard.step2.description')"
    />
    <div class="options">
      <wizzard-large-card
        v-for="(card, i) in cards"
        :key="i"
        v-bind="card"
        @click="handleSetSelectedStrategy"
      />
    </div>
  </div>
</template>

<script>
import {
  BALANCED_STRATEGY,
  LONG_ONLY,
  WIZZARD_STEPS,
} from "../../utils/constants";
import { mapGetters, mapMutations } from "vuex";
import StepHeading from "./StepHeading.vue";
import WizzardLargeCard from "./WizzardLargeCard.vue";
import balancedGraphics from "../../assets/img/wizzardCards/balanced_strategy_graphics.png";
import longOnlyStrategyGraphics from "../../assets/img/wizzardCards/longOnly_strategy_graphics.png";
export default {
  components: { WizzardLargeCard, StepHeading },
  name: "second-step-wizzard",
  data() {
    return {
      cards: [
        {
          title: this.$t("stockPickingLab.wizzard.step2.card1.title"),
          attribute: this.$t("stockPickingLab.wizzard.step2.card1.attribute"),
          description: this.$t(
            "stockPickingLab.wizzard.step2.card1.description"
          ),
          imgUrl: longOnlyStrategyGraphics,
          btnText: this.$t("stockPickingLab.wizzard.step2.card1.btnText"),
          bg: "#C4E9E7",
          id: LONG_ONLY,
        },
        {
          title: this.$t("stockPickingLab.wizzard.step2.card2.title"),
          attribute: this.$t("stockPickingLab.wizzard.step2.card2.attribute"),
          description: this.$t(
            "stockPickingLab.wizzard.step2.card2.description"
          ),
          imgUrl: balancedGraphics,
          btnText: this.$t("stockPickingLab.wizzard.step2.card2.btnText"),
          bg: "#DBE9C4",
          id: BALANCED_STRATEGY,
        },
      ],
    };
  },
  methods: {
    ...mapMutations("wizzardStore", ["setSelectedStrategy"]),
    handleSetSelectedStrategy(id) {
      this.setSelectedStrategy(id);
      this.$router.push(WIZZARD_STEPS[2]);
    },
  },
  computed: {
    ...mapGetters("wizzardStore", ["noEdit"]),
  },
};
</script>

<style lang="scss" scoped>
.second-step {
  .options {
    display: flex;
    justify-content: center;
    column-gap: toRem(32);
    flex-wrap: wrap;
  }
  .link{
    text-decoration: none;
    a{
      color: $primaryColor;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>